<template>
	<div>
		<el-table
	    :data="saleData"
	    :span-method="objectSpanMethod"
		empty-text='No Data'
		:row-class-name="tableRowClassName"
		:cell-class-name="({row, column, rowIndex, columnIndex})=>checkStateRound(row, column, rowIndex, columnIndex)"
	    class="width-max">
	    <el-table-column
	      prop="name"
	      label="Team"
		  header-align="center">
		  <template slot-scope="scope">
            	<el-popover trigger="hover" placement="right">
		          <p>Branding Rate: <span class="color-primary">{{ $options.filters.comma(scope.row.branding) }}</span> points</p>
		          <p>R&D Rate: <span class="color-primary">{{ $options.filters.comma(scope.row.rd) }}</span> points</p>
		          <p>เงินสดในมือ: <span class="color-primary">{{ $options.filters.comma(scope.row.cash) }}</span> บาท</p>
		          <div slot="reference" class="d-inline">
		            <div size="medium">{{ scope.row.name }}</div>
		          </div>
		        </el-popover>
            </template>
	    </el-table-column>
	    <el-table-column
	      prop="country"
	      label="Country"
	      width="113"
		  header-align="center">
	    </el-table-column>
	    <el-table-column label="ข้าวสารขาว" header-align="center" class="color-danger">
			<el-table-column
				prop="whiteRice.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.whiteRice.sold==scope.row.whiteRice.soldq && scope.row.whiteRice.sold!=0,'color-danger':scope.row.whiteRice.sold == 0 && scope.row.whiteRice.sold < scope.row.whiteRice.soldq }">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.whiteRice.sold < scope.row.whiteRice.soldq && scope.row.whiteRice.sold != 0}">{{scope.row.whiteRice.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.whiteRice.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.whiteRice.soldq ==0" class="color-primary">-</span><span v-else class="color-primary">${{scope.row.whiteRice.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="whiteRice.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	    <el-table-column label="ข้าวสารหอม" header-align="center">
			<el-table-column
				prop="jasmineRice.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.jasmineRice.sold==scope.row.jasmineRice.soldq && scope.row.jasmineRice.sold!=0,'color-danger':scope.row.jasmineRice.sold ==0 && scope.row.jasmineRice.sold<scope.row.jasmineRice.soldq}">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.jasmineRice.sold < scope.row.jasmineRice.soldq && scope.row.jasmineRice.sold != 0}">{{scope.row.jasmineRice.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.jasmineRice.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.jasmineRice.soldq ==0" class="color-primary">-</span><span v-else class="color-primary">${{scope.row.jasmineRice.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="jasmineRice.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	    <el-table-column label="ข้าวขาวนึ่งกระป๋อง" header-align="center">
			<el-table-column
				prop="whiteRiceCan.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.whiteRiceCan.sold==scope.row.whiteRiceCan.soldq && scope.row.whiteRiceCan.sold!=0,'color-danger':scope.row.whiteRiceCan.sold == 0 && scope.row.whiteRiceCan.sold < scope.row.whiteRiceCan.soldq}">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.whiteRiceCan.sold < scope.row.whiteRiceCan.soldq && scope.row.whiteRiceCan.sold != 0}">{{scope.row.whiteRiceCan.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.whiteRiceCan.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.whiteRiceCan.soldq ==0" class="color-primary">-</span><span class="color-primary" v-else>${{scope.row.whiteRiceCan.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="whiteRiceCan.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	    <el-table-column label="ข้าวหอมนึ่งกระป๋อง" header-align="center">
			<el-table-column
				prop="jasmineRiceCan.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.jasmineRiceCan.sold==scope.row.jasmineRiceCan.soldq && scope.row.jasmineRiceCan.sold!=0,'color-danger':scope.row.jasmineRiceCan.sold == 0 && scope.row.jasmineRiceCan.sold<scope.row.jasmineRiceCan.soldq}">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.jasmineRiceCan.sold < scope.row.jasmineRiceCan.soldq && scope.row.jasmineRiceCan.sold != 0}">{{scope.row.jasmineRiceCan.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.jasmineRiceCan.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.jasmineRiceCan.soldq ==0" class="color-primary">-</span><span v-else class="color-primary">${{scope.row.jasmineRiceCan.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="jasmineRiceCan.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	    <el-table-column label="น้ำมันรำข้าวสกัดบริสุทธิ์" header-align="center">
			<el-table-column
				prop="riceBranOil.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.riceBranOil.sold==scope.row.riceBranOil.soldq && scope.row.riceBranOil.sold!=0,'color-danger':scope.row.riceBranOil.sold ==0 && scope.row.riceBranOil.sold<scope.row.riceBranOil.soldq}">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.riceBranOil.sold < scope.row.riceBranOil.soldq && scope.row.riceBranOil.sold != 0}">{{scope.row.riceBranOil.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.riceBranOil.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.riceBranOil.soldq ==0" class="color-primary">-</span><span v-else class="color-primary">${{scope.row.riceBranOil.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="riceBranOil.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	    <el-table-column label="เครื่องดื่มข้าวชูกำลัง" header-align="center">
			<el-table-column
				prop="riceEnergyDrink.soldq"
				label="Sold Q"
				header-align="center"
				align="right"
				width="113">
				<template slot-scope="scope">
					<div>
						<span :class="{'color-success':scope.row.riceEnergyDrink.sold==scope.row.riceEnergyDrink.soldq && scope.row.riceEnergyDrink.sold!=0,'color-danger':scope.row.riceEnergyDrink.sold ==0 && scope.row.riceEnergyDrink.sold<scope.row.riceEnergyDrink.soldq}">
							<el-tooltip content="ขายแล้ว" placement="bottom" effect="light"><span :class="{'color-warning':scope.row.riceEnergyDrink.sold < scope.row.riceEnergyDrink.soldq && scope.row.riceEnergyDrink.sold != 0}">{{scope.row.riceEnergyDrink.sold}}</span></el-tooltip>/<el-tooltip content="เสนอขาย" placement="bottom" effect="light"><span>{{scope.row.riceEnergyDrink.soldq}}</span></el-tooltip>
						</span>
						<sup> [<span v-if="scope.row.riceEnergyDrink.soldq ==0" class="color-primary">-</span><span v-else class="color-primary">${{scope.row.riceEnergyDrink.price}}</span>]</sup>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="riceEnergyDrink.total"
				label="Total"
				header-align="center"
				align="right"
				width="57">
			</el-table-column>
	    </el-table-column>
	  </el-table>
		<h2 class="mg-t-2">ราคาซื้อขาย สูงสุด - ต่ำสุดของรอบปัจจุบัน</h2>
		<el-table
		:cell-class-name="({row, column, rowIndex, columnIndex})=>checkBorderPrice(row, column, rowIndex, columnIndex)"
		    :data="priceData"
			empty-text='No Data'
		    class="width-max">
		    <el-table-column
		      prop="name"
		      label="Country"
		      header-align="center">
			</el-table-column>
			<el-table-column label="ข้าวสารขาว" header-align="center">
				<el-table-column
					prop="maxP1"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP1 != null">
							{{scope.row.maxP1}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP1"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP1 != null">
							{{scope.row.minP1}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
			<el-table-column label="ข้าวสารหอม" header-align="center">
			    <el-table-column
					prop="maxP2"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP2 != null">
							{{scope.row.maxP2}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP2"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP2 != null">
							{{scope.row.minP2}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
			<el-table-column label="ข้าวขาวนึ่งกระป๋อง" header-align="center">
			    <el-table-column
					prop="maxP3"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP3 != null">
							{{scope.row.maxP3}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP3"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP3 != null">
							{{scope.row.minP3}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
			<el-table-column label="ข้าวหอมนึ่งกระป๋อง" header-align="center">
			    <el-table-column
					prop="maxP4"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP4 != null">
							{{scope.row.maxP4}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP4"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP4 != null">
							{{scope.row.minP4}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
			<el-table-column label="น้ำมันรำข้าวสกัดบริสุทธิ์" header-align="center">
			    <el-table-column
					prop="maxP5"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP5 != null">
							{{scope.row.maxP5}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP5"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP5 != null">
							{{scope.row.minP5}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
			<el-table-column label="เครื่องดื่มข้าวชูกำลัง" header-align="center">
			    <el-table-column
					prop="maxP6"
					label="Max ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.maxP6 != null">
							{{scope.row.maxP6}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			    <el-table-column
					prop="minP6"
					label="Min ($)"
					header-align="center"
					align="right"
					width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.minP6 != null">
							{{scope.row.minP6}}
						</div>
						<div v-else>
							-
						</div>
					</template>
			    </el-table-column>
			</el-table-column>
		</el-table>
		<div v-if="room.mode == 'single' && room.current == 'Sold Q Announcement'" class="mg-t-3 text-right">
			<el-row justify="end" type="flex">
                <el-col :span="4">
					<el-button class="w-100" type="primary" @click="nextPage()" round >Next</el-button>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>

import { HTTP } from'@/service/axios'

export default{
	props: ['priceData','saleData'],
	computed:{
		user(){
			return this.$store.state.user
		},
		room(){
			return this.$store.state.room
		}
	},
	data(){
		return{
	        calTotal:''
		}
	},
	methods:{
		async nextPage(){
			this.room.current = 'Financial product'
			await this.$store.dispatch('updateCurrent',this.room)
			await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
		},
		tableRowClassName({row, rowIndex}) {
			if(this.user.userLevel == 0){
				if (rowIndex === 1 || rowIndex === 0) {
		          return 'background-color-table';
		        }
			} 
			return '';
	        
	      },
		 checkStateRound(row, column, rowIndex, columnIndex){
	      if(columnIndex > 1 && (columnIndex % 2 != 0) || columnIndex == 1){
	         return 'border-right'
	      }
	    },
	    checkBorderPrice(row, column, rowIndex, columnIndex){
	    	if(columnIndex % 2 == 0){
		         return 'border-right'
		     }
	    },
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 5 || columnIndex === 7 || columnIndex === 9 || columnIndex === 11 || columnIndex === 13) {
			if (rowIndex % 2 === 0) {
				return {
				  rowspan: 2,
				  colspan: 1,
				};
			} else {
				return {
				  rowspan: 0,
				  colspan: 0
				};
			}
        }
      }
	}
}
</script>